.admin-topbar {
    position: fixed;
    top: 0;
    right: 0;
    height: 77px;
    width: calc(100% - 263px);
    display: block;
    z-index: 1;
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
    padding-right: 50px;
  }

  .button-menu-mobile{
    display: none;
    border: none;
    height: 70px;
    line-height: 70px;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    float: left;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .topBarText{
    float: right;
    font-size: 15px;
    margin-right: 40px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fafbfd;
    color: #98a6ad;
    height: 77px;
    line-height: 75px;
  }

  .teamNameBox{
    float: right;
    width: 15%;
    text-align: center;
    line-height: 50px;
    border: 1px solid black;
    height: 77px;
    background-color: #fffff0
  }

  @media (max-width: 767px) {
    .button-menu-mobile.disable-btn {
      display: inline-block;
    }
    .topBarText{
      margin-right: 0px;
    }
    .headerText{
      display: none;
    }
    .teamNameBox{
      display: none;
    }
    .admin-topbar {
      padding-right: 0px;
      width: 100%;
      z-index: 1;
    }
  }
